import React from 'react'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import SectionHeader from '../../../components/SectionHeader/SectionHeader'
import Intro from '../../../components/Intro/Intro'
import Comparison from '../../../components/comparison/Comparison';
import HubSpotForm from '../../../components/HubSpotForm/HubSpotForm'
import Outcomes from '../../../components/Outcomes/Outcomes'

const EmersoftVsBookshop = () => {

    const comparisonData = [
        {
          heading: "Emersoft Ingram Shopify",
          items: [
            {
              heading: "Membership Restrictions",
              description: "Open to all bookstores (online & physical)"
            },
            {
              heading: "Store Ownership & Branding",
              description: "Full control over store, branding, and customer experience"
            },
            {
              heading: "Revenue & Payouts",
              description: "Instant payments as sales happen"
            },
            {
              heading: "Product Catalog",
              description: "Access to Ingram’s full catalog and ability to list non-Ingram products"
            },
            {
                heading: "Order Fulfillment & Print-on-Demand",
                description: "Fully automated, including print-on-demand and custom stock sales"
            },
            {
                heading: "Profit Margins",
                description: "100% profit retention, leveraging Ingram’s wholesale pricing"
            },
            {
                heading: "Integration & eCommerce Control",
                description: "Direct integration with Shopify for seamless eCommerce management"
            },
            {
                heading: "Integrations & Marketplacel",
                description: "Leverages Shopify’s extensive ecosystem of third-party apps and solutions"
            },
            {
                heading: "Community Support",
                description: "Access to Shopify’s large community and marketplace for various solutions"
            },
            {
                heading: "Reporting & Accounting",
                description: "Extensive reporting system with integrations to major accounting software via Shopify"
            }
          ]
        },
        {
          heading: "Bookshop.org",
          items: [
            {
              heading: "Membership Restrictions",
              description: "Requires ABA membership and a physical location"
            },
            {
              heading: "Store Ownership & Branding",
              description: "Customers shop on Bookshop.org’s platform, not the bookstore’s"
            },
            {
              heading: "Revenue & Payouts",
              description: "Bookstores receive revenue share every six months; affiliate revenue requires a $20 threshold"
            },
            {
              heading: "Product Catalog",
              description: "Limited to books stocked by Ingram"
            },
            {
                heading: "Order Fulfillment & Print-on-Demand",
                description: "Bookstores cannot sell their own inventory"
            },
            {
                heading: "Profit Margins",
                description: "Bookshop.org gives bookstores 30% of sales when a bookstore is chosen; otherwise, profits are shared among all Bookshop members"
            },
            {
                heading: "Integration & eCommerce Control",
                description: "Separate marketplace, requiring bookstores to direct traffic to Bookshop.org"
            },
            {
                heading: "Integrations & Marketplacel",
                description: "Limited integration options; primarily offers affiliate linking and widgets"
            },
            {
                heading: "Community Support",
                description: "Community support mainly through Bookshop.org’s own network"
            },
            {
                heading: "Reporting & Accounting",
                description: "Limited built-in reporting; no direct integration with accounting software"
            }
          ]
        }
      ];

      const pricingMonthly = [
        {
          id: 111,
          title: '$3000 one-time',
          subtitle: 'Shopify Ingram App Setup Fee',
        },
        {
          id: 222,
          title: '$300 / month',
          subtitle: 'Data and App Access',
        },
      ]
    
      const pricingYearly = [
        {
          id: 111,
          title: '$1500 one-time',
          subtitle: 'Shopify Ingram App Setup Fee (50% off)',
        },
        {
          id: 222,
          title: '$250 / month',
          subtitle: 'Data and App Access ($3000 billed yearly)',
        },
      ]
    return (
        <Layout>
            <SEO
            title="Emersoft's Ingram Shopify App: The Superior Choice Over Bookshop.org for Independent Bookstores"
            keywords={[
            `Emersoft`,
            `application`,
            `react`,
            `ai`,
            `gpt`,
            `openai`,
            `Emersoft Ingram Shopify Integration`,
            `Bookshop.org comparison`,
            `Shopify bookstore solutions`,
            `Independent bookstore e-commerce`,
            `Best online bookstore platform`
            ]}
            description="Discover how Emersoft's Ingram Shopify Integration empowers bookstores with full control, higher profits, and seamless Shopify integration, surpassing Bookshop.org's limitations."
        />
        <SectionHeader>
            Why Choose Emersoft’s Ingram Shopify Integration Over Bookshop.org?
        </SectionHeader>
        <Intro
            paragraph1="Scale Your Bookstore Without Limits"
        />
        <Comparison data={comparisonData} />
        <Intro paragraph1="Pricing" />
        <Outcomes heading="Monthly" outcomes={pricingMonthly} noMarginBottom={true} />
        <Outcomes heading="Annually" outcomes={pricingYearly} />
        <HubSpotForm 
            formId="93f2ee63-651c-439f-bdff-9c02aafe3e74" 
            portalId="46844019" 
            heading="Start Automating Today"
            paragraph="Get started with Ingram's Shopify integration today and see the difference it makes for your business."
        />
        </Layout>
    )
}

export default EmersoftVsBookshop;